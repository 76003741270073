import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ST from './style'
import BtnFunc from './BtnFunc'
import { $ , r, S } from './utils'
import globalState from './globalState';

export default function NavBar() {
    // const { pathname } = useLocation();
    useEffect(()=>{

        // hide elements that have the class .close on outside click
        const root = document.querySelector('#root');
        if(!root.dataset.close_windows){
            root.dataset.close_windows = true
            const els =  document.querySelectorAll('.close')
            root.addEventListener('mousedown', e=>{
                els.forEach(el => {
                    if(e.target.classList.contains('cl-null')){return}
                    if(e.path && e.path.includes(el)){return}
                    if(el.classList.contains(ST('h-0'))){return}
                    el.classList.add(ST('h-0'))
                })
            })
        }

        const navbBg = $('#navbBg')[0]
        const top = $('#top')[0]
        const spans = $('.navbar-brand > .ani-2')
        const options = {threshold: 1}
        if(globalState.observedElements.includes(navbBg)){ return }
        globalState.observedElements.push(navbBg)

        const tracker = new IntersectionObserver(()=>{
            // r(top).y == 0 && navbBg.classList.remove('cus-bg-1') 
            r(top).y != 0 && spans.forEach((el,i) => {
                i != 0 && el.classList.add('w-0')
                i == 0 && setTimeout(()=>el.classList.add('w-0'),400)
            } )
            r(top).y == 0 && spans.forEach((el,i) => {
                i == 0 && el.classList.remove('w-0')
                i != 0 && setTimeout(()=>el.classList.remove('w-0'),400)
            } )
        }, options)
        tracker.observe(top)
    },[])

    return ( <>
        <div id="top" ></div>
        <nav className={ST("navbar df jcsb sticky-top navbar-dark")} style={S('--z:100; --w:100%; --px:4; --py:1.2;')} >
            <div id='navbBg' className={ST("abs ani-2 cus-bg-1")} 
                style={S('--w:100%;--h:105%;--left:0; --top:-5%; --z:-1; --blur:18;')}>
            </div>
            <div className="df navbar-brand" style={S('--g:0; --fw:700; --fvw:.5; --fs:1.2em; --lh:1;')}>
                <span className='dib gradient-clip-light' style={S("")} >C</span>
                <span className='dib gradient-clip-light ani-2 overflow-hidden' style={S("--w:2.3em; --m:0;")} >arlos</span>
                <span className='dib gradient-clip-light' style={S("--ml:1.2;")} >J F</span>
                <span className='dib gradient-clip-light ani-2' style={S("--w:2.8em; ")}>ebres</span>
            </div>
            <div className="df d-none d-md-flex" style={S('--fs:1.2em;')}
                >{ ['Home,top','Projects,projects_section','About,about_section','Contact,contact_section'].map(v=> ( 
                    <div key={`${v.split(',')[0]}-links`}>
                        <Link className={ST("ani nav-link react-link")} 
                            style={S('--c:white; --focus-bg:hsl(0,0%,50%,.3); --hover-bg:hsl(10,0%,50%,.3); --hover-c:white;')} 
                            to={`/?section=${v.split(',')[1]}`} 
                            >{v.split(',')[0]}
                        </Link>
                        <a className={`alternateLink ani d-none ${ST('nav-link')}`} 
                            style={S('--c:white; --focus-bg:hsl(0,0%,50%,.3); --hover-bg:hsl(10,0%,50%,.3); --hover-c:white;')} 
                            href={`${globalState['rootAddress']}?section=${v.split(',')[1]}`}
                            >{v.split(',')[0]}
                        </a>
                    </div> 
                ))
            } </div>
            {/* small screen menu */}
            <div id="menu_btn_div" className={ST('rel cl-null d-md-none')} style={S('--minw:30px;')}> 
                <button id="menu_btn" name="menu"
                    onClick={()=>BtnFunc([{action:'toggle_class:h-0', target:'#menu_box'}])} 
                    className={ST("navbar-toggler ")} type="button"
                    style={S('--py:1; --px:2; --bg:hsl(0,0%,0%,.6); --br:6px;')} >
                    <span className={ST('navbar-toggler-icon cl-null')} style={S('--w:20px;')}></span>
                </button>

                <div className={ST("close df ais text-center flex-column ani-h h-0 no-wrap menu_box")}
                    id="menu_box" style={S('--h:190px; --g:0; --bg:rgb(0,0,0,0.9);')}
                    // onClick={e=>BtnFunc([{e:e, action:'toggle_class:h-0', target:'#menu_box'}])}
                    >{ ['Home,top','Projects,projects_section','About,about_section','Contact,contact_section'].map((v,i)=> ( 
                        <div className='df jcc flex-fill' key={`${v.split(',')[0]}-links-sm`} >
                            <Link className={ST("nav-link react-link ")} 
                                to={`/?section=${v.split(',')[1]}`} 
                                style={S('--c:white; --focus-bg:hsl(0,0%,50%,.3); --hover-bg:hsl(10,0%,50%,.3);--hover-c:white;')}
                                onClick={() => BtnFunc([{action:'scrollTo', target:'#top'}, ])} 
                                >{v.split(',')[0]}
                            </Link>
                            <a className={`alternateLink ani d-none ${ST('nav-link ')}`} 
                                style={S('--c:white; --focus-bg:hsl(0,0%,50%,.3); --hover-bg:hsl(10,0%,50%,.3);--hover-c:white;')}
                                href={`${globalState['rootAddress']}?section=${v.split(',')[1]}`}
                                >{v.split(',')[0]}
                            </a>
                        </div> 
                    )) } 
                </div>
            </div>
            <div id="main-modal" className={ST('modal aic jcc p-3')} style={S('--bg:rgb(0,0,0,0.5); --blur:8;')}
                onClick={ (e) => BtnFunc([
                    {e:e, action:'close:fade-in,df', target:'#main-modal', delay:500},
                    {action:'remove_class:overflow-hidden', target:'body'},
                    ])} >
                <div id="form-messageBox" className={ST('p-4 bg-dark rounded border gap d-flex aifs shadow jcsb')} >
                    <h2 className={`message ${ST('fs-[1.5em] c-[#fefefe] m-0 fade-in')}`}>Message</h2>
                    <button onClick={ e => BtnFunc([
                        {action:'remove_class:fade-in,df', target:'#main-modal'},
                        {action:'remove_class:overflow-hidden', target:'body'},
                    ] )} className={ST('m-0 btn btn-outline-secondary')}>X</button>
                </div>
            </div>
        </nav>
        </>);
}
