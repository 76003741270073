
function $ (data) {
    if(typeof(data) === 'string'){ return [...document.querySelectorAll(data)]}
    if(Array.isArray(data)){ return data.map(el=>[...document.querySelectorAll(el)]).flat() }
}
function capitalize (str){
    let name = ''
    str.toLowerCase().split(' ').forEach(word =>{ name += `${word[0].toUpperCase() + word.slice(1)} ` })
    return name.slice(0,-1)
}
function r(el){
    if(typeof(el) === 'string'){return $(el)[0].getBoundingClientRect()}
    return el.getBoundingClientRect()
}
function importImg(str){
    function importAll(r) {
        let images = {};
        r.keys().map(item => images[item.replace('./', '')] = r(item));
        return images;
    }
    return importAll(require.context('./img', false, /\.(png|jpe?g|svg)$/))[str];
}
function S(str){
    const d = {}
    str = str.split(';').filter(v=>v!='')
    str.forEach(v=>{
        v=v.trim().split(':')
        d[v[0]] = v.slice(1).join(':')
    })
    return d
}
export { $, capitalize, r, importImg, S }
