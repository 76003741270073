import ST from './style'
import globalState from './globalState';
import { $, capitalize, r } from './utils'
export default function BtnFunc(arr){
    arr.forEach((obj=>{
        obj.hasOwnProperty('e') && obj.preventDefault != 'false' && obj.e.preventDefault();
        const el = obj.target ? $(obj.target)[0] : null
        const action = obj.action.split(':')
        const funcs = {
            'toggle_class': ()=> action[1].split(',').forEach( c=> { el.classList.toggle(ST(c)) }),
            'remove_class': ()=> action[1].split(',').forEach( c=> el.classList.remove(ST(c))),
            'update_navegateTo': ()=>  { $('nav').dataset.nav = obj.target },
            'update_scrollTo': ()=> { globalState['scrollTo']= obj.target },
            'scrollTo': ()=> { el && el.scrollIntoView() },
            'close': ()=>  { 
                obj.e.target === el && action[1].split(',').forEach( c=> { el.classList.remove(ST(c))}) 
            } ,
            'submit': ()=>{
                const modal = $('#main-modal')[0]
                const messageEl = modal.querySelector('.message')
                const vals = [...$( `${obj.form} .formInput` )].map(v=>v.value)

                $('body')[0].classList.add(ST('overflow-hidden'))
                
                if(vals.some(v=>v =='') || !vals[1].includes('@')){ 
                    messageEl.innerText = 'Please verify that the Name and Email \n are correct and try again'
                    modal.classList.add(ST('fade-in'), ST('df'))
                    return 
                }
                modal.classList.add(ST('fade-in'), ST('df'))
                messageEl.innerText = 'Sending...'
                const header = {
                    method: 'POST',
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        name: capitalize(vals[0]),
                        email: 'febresllc@gmail.com',
                        html: `
                            <h3>Message:</h3> <p>${vals[2]}</p> 
                            <br> 
                            <h3>Name:</h3> <p>${capitalize(vals[0])}</p> 
                            <h3>Email:</h3> <p>${vals[1]}</p>
                        `
                    })
                }
                fetch(obj.address, header)
                .then( res => { 
                    if(!res.ok) { console.log("Failure:", res); throw new Error('HTTP ' + res.status);
                    } else { return res.json() }
                })
                .then(res=> {
                    messageEl.classList.add(ST('fade-out'))
                    setTimeout(()=>{ messageEl.innerText = 'Thank you for contacting me. \n I will reply to you as soon as posible.' }, 500)
                    setTimeout(()=>{ messageEl.classList.remove(ST('fade-out')) }, 1000)
                })
                .catch(err => console('error', err))
            } ,
        }
        funcs.hasOwnProperty(action[0]) && funcs[action[0]]()
    }))
}
