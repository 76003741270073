import { lazy, Suspense, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import About from './About';
import globalState from './globalState';
import './globalStyle.css';
import ST from './style';
import { $, S } from './utils';

const Gallery = lazy(()=> import('./Gallery'));
const Contact = lazy(()=> import('./Contact'));

const Home = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const section = params.get('section');
    useEffect(() => {
        globalState['isMainAppLoaded'] = 'true'
        !section && setTimeout(() => $(globalState['scrollTo'])[0].scrollIntoView({}) , 300) 
        section && setTimeout(() => $(`#${section}`)[0].scrollIntoView({}) , 300) // to scroll to section when main page was not loaded
    } ,[section]);
    
    return (<>
        <section id='section-1' className={ST("container-fluid ")} style={S('--p:0; --pos:relative; --pb:0; --px-md:8; --v1:30%; --bg:white;')}>
            <div id="blue-gradient" className={ST("abs")} 
                style={S('--w:60%; --h:calc(100% + var(--v1)); --top:calc(var(--v1)*-1); --left:0; --z:3; --blur:25; --bg:hsl(235,85%,10%,.5);')}>
            </div>
            <div className={ST("abs")} 
                style={S('--w:100%; --h:calc(100% + var(--v1)); --top:calc(var(--v1)*-1); --left:0; --z:4; --blur:5;')}>
            </div>
            <div className={ST("abs bg-img-1 overflow-hidden df jcc")} style={S('--w:100%; --h:calc(100% + var(--v1));  --z:2; --top:calc(var(--v1)*-1); --left:0; --pt:60;')}> 
                <div style={S('--bg:rgb(0,0,0,.5); --w:100%; --h:calc(100% + var(--v1));  --z:2; --top:calc(var(--v1)*-1); --left:0;')}></div>
            </div>
            <div className={ST('container rel')} style={S('--z:5;')}>
                <div className={ST("row d-flex flex-row-reverse")} style={S('--pt:18;--pt-md:25;')}>
                    <div className={ST("df jcc")} style={S('--col:12; --col-md:6; --h-lg:min(40vw, 1000px); --c:white;')}>
                        <div className={ST('df rel ')} style={S('--w:60%; --w-md:90%;--w-lg:70%; --ar:1/1.3; --max-fvw:4rem;')}>
                            <span className='wm-vlr h1 gradient-clip-1' style={S('--fw:700; --pos:absolute; --top:.1em; --right:-.8em; --fvw:3.5;')}>PORTFOLIO</span>
                            <div className={ST('df jcc avatar-1 bg-cover rel overflow-hidden')} style={S('--wh:100%;')}>
                                <span className='wm-vlr h1 portfolio-span' style={S('--fw:700; --pos:absolute; --top:.1em; --right:-.8em; --fvw:3.5;')}>PORTFOLIO</span>
                            </div>
                        </div>
                    </div>
                    
                    <div className={ST("df jcc flex-column")} style={S('--col-md:6;--minh:30vw;--px-md:0; --pt:15; --pt-md:0;')}>
                        <h2 className={ST("df jcc md-jcfs")} style={S('--c:white; --gy:0; --gx:3; --fw:300; --v1:2;')} >
                            <p className='gradient-clip-light h2' style={S('--fw:600; --c:white; --ml:-.2; --fvw:4.5;')}
                                >Hello
                            </p>
                            <hr style={S('--w:100%;--op:0; --m:0; --fvw:var(--v1);')}/>
                            <p className="" style={S('--fvw:var(--v1);')}>I'm</p>
                            <p className={ST('italic')} style={S('--fvw:var(--v1);')}>Carlos</p>
                            <p style={S('--fvw:var(--v1);')}>Febres.</p>
                            <hr style={S('--w:100%;--op:0; --m:0;--fvw:var(--v1);')}/>
                            <p className={ST("gradient-clip-light")} style={S('--fw:700;--fvw:var(--v1);')}
                                >Email
                            </p>
                            <p style={S('--fw:500;--fvw:var(--v1);')}> Developer</p>
                        </h2>
                    </div>
                </div>
            </div>
            <div className={ST("rel linearFade-[180,50,100]")} 
                style={S('--w:100%; --h:100px; --h-md:150px; --z:3;  --blur:5; --bg:hsl(235,85%,10%,.4); --op:0;')}>
            </div>
        </section>

        <div className={ST("spacer-5")}></div>
        <Gallery />
        <Suspense fallback={<div>LOADING</div>}> <About /></Suspense> 
        <Suspense fallback={<div>LOADING</div>}> <Contact /></Suspense> 
    </>);
}
export default Home;


