import {BrowserRouter, Route, Routes } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import NavBar from './Navbar';
import Home from './Home';
import ST from './style'
import { S } from './utils'
const EmailPage = lazy(()=> import('./EmailPage'));


export default function App(){
    return (
        <section className={ST('m-auto shadow')} style={S('--maxw:2000px;')}>
            <BrowserRouter >
                <NavBar />
                <Routes>
                    <Route path='/' element={ <Home /> } />
                    <Route path='/email/:email' element={ <Suspense fallback={<div>LOADING</div>}> <EmailPage/></Suspense>} />
                    <Route path='/*' element={ <Home /> } />
                </Routes>
            </BrowserRouter>
        </section>
    );
}

