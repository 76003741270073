import ST from './style'
import { S } from './utils'

const About = () => {
    return ( <>
        <section id="about_section" className={ST("container-fluid")} style={S('--pt:24; --pt-md:35; --px-md:8; --pb:32;')}>
            <div className={ST("container")}>
                <div className={ST('row aifs')} style={S('--mb:8; --row-g:20; --v1:.13em;')}>
                    <div className={ST("flex-column")} style={S('--col:12;--col-lg:7; --pl-md:0; --pr-md:12;')}>
                        <h1 className={ST('nowrap gradient-clip-light')} style={S('--mb:2; --fw:bold; --fvw:2;')}
                            >ABOUT ME
                        </h1>
                        <p style={S(' --fw:700; --fvw:.5; --mb:4; --c:hsl(0,0%,40%);')}>
                            I'm an Email Developer able to build dynamic and responsive emails from the ground up - from concept, and layout, to the final design. 
                        </p>
                        <p style={S('--fw:500; --fvw:.5; --c:gray; --mb:0;')}>
                            I love challenges and learning new things.
                            The feeling of developing something to solve a problem is very gratifying to me, that's why I love email development.
                            The number of limitations around Emails makes me go the extra mile to find new ways to push boundaries in what I develop.
                            <br/><br/>
                            Skilled at writing well-designed, testable, and efficient code using current best practices in Email development. 
                            Fast learner, hard worker, and team player, proficient in various scripting languages and multimedia Web tools.
                        </p>
                        <br/>
                        <h2 style={S('--fw:bold; --fvw:.5;')}>Education </h2>
                        <p style={S('--fw:700; --fvw:.4; --c:hsl(0,0%,40%);')}>Valencia College: <span style={S('--fw:500; --fvw:0; --c:gray;')}>Computer Science - Web design</span></p>
                        <p style={S('--fw:700; --fvw:.4; --c:hsl(0,0%,40%);')}>CodingPhase: <span style={S('--fw:500; --fvw:0; --c:gray;')}>HTML Email Developement</span></p>
                        <p style={S('--fw:700; --fvw:.4; --c:hsl(0,0%,40%);')}>Languages: <span style={S('--fw:500; --fvw:0; --c:gray;')}>English - Spanish - Portuguese</span></p>
                    </div>
                    <div style={S('--col:12;--col-lg:5; --p-md:0; --v1:12px; --v2:.10em; ')}>
                        <div className={ST("df acfs gradient-2")} style={S('--p:4; --pb:6; --p-md:6; --br:.4em; --c:hsl(0,0%,90%); --c1:hsl(210,30%,30%); --c2:white;')}>
                            <h2 className={ST('rel')} style={S('--fw:bold; --w:100%;')}>
                                <span className={ST('gradient-1 abs')} style={S('--h:1em; --w:7px; --br:3px; --top:var(--v2);')}></span>
                                <span style={S('--pl:2;')}>Skills</span>
                            </h2>
                            <div className={ST("df")} style={S('--pl:3; --pr:0; --ch-m:0; --g:3;')}> {
                                ['Salesforce Marketing Cloud','HTML 5','CSS','JavaScript','Email Templates','CAN-SPAM','Figma','Adobe Photoshop','Google Analytics','AMPscript','Email Marketing','HTML Emails','Email Newsletter Design','Landing Pages' ].map((v,i)=>(
                                    <p key={'sk-'+i} className={ST('')} style={S('--lh:1em; --epx:2.5;--epy:2; --br:.25em; --c:var(--c2); --bg:var(--c1);')}>{v}</p>
                                ))
                            }</div>
                            <hr style={S('--w:100%; --m:3; --bg:white;')}/>

                            <h2 className={ST('rel')} style={S('--fw:bold; --w:100%;')}>
                                <span className={ST('gradient-1 abs')} style={S('--h:1em; --w:7px; --br:3px; --top:var(--v2);')}></span>
                                <span style={S('--pl:2;')}>Tools</span>
                            </h2>
                            <div className={ST("df")} style={S('--pl:3; --pr:0; --ch-m:0; --g:3;')}> {
                                ['Visual Studio Code','Litmus','Can I Email','TinyPNG','Figma','UnCSS','CodeBeautify'].map((v,i)=>(
                                    <p key={'ot-sk-'+i} className={ST('nowrap')} style={S('--lh:1em; --epx:2.5;--epy:2; --br:.25em;--c:var(--c2); --bg:var(--c1); --fw:500;')}>{v}</p>
                                ))
                            }</div>
                            <hr style={S('--w:100%; --m:3; --bg:white;')}/>

                            <h2 className={ST('rel')} style={S('--fw:bold; --w:100%;')}>
                                <span className={ST('gradient-1 abs')} style={S('--h:1em; --w:7px; --br:3px; --top:var(--v2);')}></span>
                                <span style={S('--pl:2;')}>Location</span>
                            </h2>
                            <a href="https://www.google.com/maps/place/Orlando,+FL/@28.4808846,-81.6235329,10z/data=!3m1!4b1!4m5!3m4!1s0x88e773d8fecdbc77:0xac3b2063ca5bf9e!8m2!3d28.5383832!4d-81.3789269?hl=en" className={ST('d-flex aic')} target="_blank" rel="noreferrer"
                                style={S('--br:5px; --p:.7;--p-md:.5; --br:5px; --bd:none; --ml:3; --bg:var(--c1);')}>
                                <div className={ST('d-flex aic')} style={S('--p:1.5; --pl:.7; --br:3px; --g:1.8;')}>
                                    <span className='df' style={S(`--wh:1.1em; --c:var(--c2);`)}>
                                        <svg height='48' viewBox='0 0 48 48' width='48' xmlns='http://www.w3.org/2000/svg'><path fill="white" d='M24 4c-7.73 0-14 6.27-14 14 0 10.5 14 26 14 26s14-15.5 14-26c0-7.73-6.27-14-14-14zm0 19c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z'/><path d='M0 0h48v48h-48z' fill='none'/></svg>
                                    </span>
                                    <p style={S('--c:var(--c2);')}>Orlando FL</p>
                                </div>
                            </a>
                            <hr style={S('--w:100%; --m:3; --bg:white;')}/>

                            <h2 className={ST('rel')} style={S('--fw:bold; --w:100%;')}>
                                <span className={ST('gradient-1 abs')} style={S('--h:1em; --w:7px; --br:3px; --top:var(--v2);')}></span>
                                <span style={S('--pl:2;')}>Contact</span>
                            </h2>
                            <a href="mailto:febres0492@gmail.com" className={ST('d-flex aic')} target="_blank" rel="noreferrer"
                                style={S('--br:5px; --p:.7;--p-md:.5; --br:5px; --bd:none; --ml:3; --bg:var(--c1);')}>
                                <div className={ST('d-flex aic')} style={S('--p:1.5; --br:3px; --g:3;')}>
                                    <span className='df' style={S(`--wh:1.1em; --c:var(--c2);`)}>
                                        <svg viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg'><path fill="white" d='M256 352c-16.53 0-33.06-5.422-47.16-16.41L0 173.2V400C0 426.5 21.49 448 48 448h416c26.51 0 48-21.49 48-48V173.2l-208.8 162.5C289.1 346.6 272.5 352 256 352zM16.29 145.3l212.2 165.1c16.19 12.6 38.87 12.6 55.06 0l212.2-165.1C505.1 137.3 512 125 512 112C512 85.49 490.5 64 464 64h-416C21.49 64 0 85.49 0 112C0 125 6.01 137.3 16.29 145.3z'/></svg>
                                    </span>
                                    <p style={S('--c:var(--c2);')}>Febres0492@gmail.com</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>);
}
export default About;
